/**
 * Commons
 */
@import 'commons/variables';
@import 'commons/placeholders';
@import 'commons/mixins';
@import 'commons/fonts';
@import '~@micromag/viewer/scss/styles';

@import 'commons/medias_queries';
@import 'commons/reset';
@import 'commons/general';
@import 'commons/animations';

html,
body {
    margin: 0;
    font-size: 16px;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
    background-color: $color-background;
}

body {
    background-color: $color-black;
    color: $color-text;
    font-family: $font-text;

    text-rendering: optimizeLegibility;
    font-feature-settings: 'liga', 'liga', 'clig', auto;
    font-kerning: auto;

    p {
        line-height: 1.3em;

        em {
            animation: textAnim 1.2s ease infinite;
            font-family: $font-title;
            font-size: 0.95em;
            line-height: 1em;
            text-transform: uppercase;
        }

        i {
            font-style: italic;
        }

        q {
            color: $color-brown;
        }

        a {
            background-color: $color-brown;
            text-decoration: none;
            text-transform: uppercase;

            &:hover {
                background-color: $color-pink;
            }
        }
    }
}

/* stylelint-disable */
html,
body,
#root {
    width: 100%;
    height: 100%;
}

.page {
    width: 100%;
    height: 100%;
}
/* stylelint-enable */

:global {
    .micromag-viewer-menus-menu-dots-dot {
        background-color: #000 !important;
    }
}
