@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    margin: 10px 0;
    padding: 20px;
    background-color: $color-black;
    font-size: 20px;
    pointer-events: auto;

    &:last-child {
        margin-bottom: 0;
    }
}
