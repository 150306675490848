/**
 * Containers
 */

%fullscreen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

%container-cols {
    display: table;
    width: 100%;
}

%container-col {
    display: table-cell;
}

/**
 * Controls
 */

%reset-button {
    display: inline-block;
    position: relative;
    padding: 0;
    border: 0;
    outline: none;
    background: transparent;
    color: inherit;
    font-family: $font-text;
    text-decoration: none;
    cursor: pointer;
    appearance: none;
}

%reset-input {
    padding: 0;
    border: 0;
    background: transparent;
    font-family: $font-text;
    cursor: pointer;
    appearance: none;
}

/**
 * Media
 */

%hide-mobile {
    display: none;
    visibility: hidden;

    @media #{$mq-medium-screen} {
        display: block;
        visibility: visible;
    }
}

%show-mobile {
    display: block;
    visibility: visible;

    @media #{$mq-medium-screen} {
        display: none;
        visibility: hidden;
    }
}

/**
 * Pages
 */

%page {
    position: relative;
    width: 100%;
    height: 100%;
}

/**
 * Text
 */

%page-title {
    font-size: 40px;
    font-weight: bold;
    text-transform: uppercase;

    @media #{$mq-medium-screen} {
        font-size: 60px;
    }
}

%paragraph {
    font-size: $font-size-normal;
    line-height: $line-height-normal;
}

%button-text {
    font-family: $font-title;
    font-size: $font-size-very-large;
    font-weight: bold;
    text-transform: uppercase;
}

%html-content {
    p {
        font-weight: bold;
    }

    em {
        font-style: italic;
    }

    strong {
        font-weight: bold;
    }
}
