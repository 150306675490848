// stylelint-disable selector-max-compound-selectors
@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';
@import '../commons/animations';

.container {
    @extend %page;
    overflow: hidden;

    .inner {
        width: 100%;
        height: 100%;
    }

    .button {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 99999;
    }

    .viewer {
        transition: background-color 0.3s ease-in-out;
        background-color: $color-background;
        height: 100%;

        &.white {
            background-color: #ffffff;
        }

        &.green {
            // background-color: #c6e813;
            background-color: #ceeb10;
        }
    }
}
