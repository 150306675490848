/**
 * Elements
 */

a {
    color: inherit;
}

h1,
h2,
h3,
h4,
h5 {
    padding-bottom: 0.5em;
    font-family: $font-title;
    font-weight: normal;

    a {
        color: inherit;
        text-decoration: none;
    }
}

h1 {
    font-size: 48px;
}

h2 {
    font-size: 32px;
}

h3 {
    font-size: 28px;
}

/**
 * Layout
 */

.clear {
    clear: both;
}

.clearfix {
    @include clearfix;
}

.container {
    margin: 0 auto;
}
