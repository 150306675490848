@import './variables';

@keyframes textAnim {
    0% {
        transform: scale(1);
        background-color: $color-pink;
        // color: $color-brown;
    }

    50% {
        transform: scale(1.3);
        background-color: $color-brown;
        // color: $color-pink;
    }

    100% {
        transform: scale(1);
        background-color: $color-pink;
        // color: $color-brown;
    }
}

@keyframes bounce7 {
    0% {
        transform: scale(1, 1) translateY(0);
    }

    10% {
        transform: scale(1.1, 0.9) translateY(0);
    }

    30% {
        transform: scale(0.9, 1.1) translateY(-100px);
    }

    50% {
        transform: scale(1.05, 0.95) translateY(0);
    }

    57% {
        transform: scale(1, 1) translateY(-7px);
    }

    64% {
        transform: scale(1, 1) translateY(0);
    }

    100% {
        transform: scale(1, 1) translateY(0);
    }
}
