/**
 * Colors
 */
$color-white: #fff;
$color-black: #000;

$color-green: #3acc7c;
$color-pink: #ff7bac;
$color-brown: #c93;

$color-light-gray: #3d4748;
$color-dark-gray: #333;

$color-text: $color-white;
$color-border: $color-green;
$color-background: $color-green;

/**
 * Fonts
 */
$font-text: 'Times New Roman', serif;
$font-computer: 'Times New Roman', serif;
$font-title: 'Times New Roman', serif;

$font-size-normal: 16px;
$font-size-medium: 20px;
$font-size-large: 24px;
$font-size-very-large: 34px;

$line-height-normal: 1.3;

/**
 * Screen size
 */

$small-screen: 500px;
$medium-screen: 790px;
$large-screen: 1000px;

$high-screen: 800px;
$small-high-screen: 500px;

$max-page-width: 1080px;

$border: 3px solid $color-border;

$button-transition: box-shadow 0.12s ease-out, transform 0.12s ease-out, color 0.12s ease-out;
$brain-transition: opacity 0.12s ease-out;

/**
 * Media queries
 */

$mq-small-screen: 'only screen and (min-width: #{$small-screen})';
$mq-medium-screen: 'only screen and (min-width: #{$medium-screen})';
$mq-large-screen: 'only screen and (min-width: #{$large-screen})';
$mq-high-screen: 'only screen and (min-width: #{$large-screen}) and (min-height: 500px)';
$mq-med-high-screen: 'only screen and (min-height: #{$high-screen})';
$mq-small-high-screen: 'only screen and (min-height: #{$small-high-screen})';
