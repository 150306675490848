@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    @extend %reset-button;
    display: inline-block;
    padding: 10px;

    .icon {
        padding: 0 5px 0 0;
    }
}
